import React from "react";
import { PAGES } from "@app/constants";
import { Button } from "@app/components";
import { trackEvent, EVENTS } from "@app/utils/trackEvent";

import { MetaData } from "../_Shared";

const TIERS = {
  FREE: "Free",
  UNLIMITED: "Unlimited",
  ENTERPRISE: "Enterprise"
} as const;

const TIERDATA = [
  {
    tier: TIERS.FREE,
    isPopular: false,
    description: "Perfect for developers who are just getting started.",
    pricePerMonth: 0,
    priceText: "Free",
    buttonVariant: "primary",
    buttonText: "Get Started",
    buttonLink: "/login",
    features: [
      "5 free builds per week",
      "Access to all supported platforms",
      "Basic cloud build services",
      "Community support"
    ]
  },
  {
    tier: TIERS.UNLIMITED,
    isPopular: true,
    description:
      "Ideal for developers who need consistent and unrestricted builds.",
    pricePerMonth: 9.99,
    priceText: "$9.99/month",
    buttonText: "Select Plan",
    buttonVariant: "secondary",
    buttonLink: "",
    features: [
      "Unlimited builds within fair use limits",
      "Access to all supported platforms",
      "Priority build queue",
      "Email support"
    ]
  },
  {
    tier: TIERS.ENTERPRISE,
    isPopular: false,
    description:
      "Tailored solutions for studios and enterprises with advanced CI/CD needs.",
    pricePerMonth: false,
    priceText: "Contact us!",
    buttonText: "Select Plan",
    buttonVariant: "secondary",
    buttonLink: "",
    features: [
      "Custom CI pipelines",
      "Unlimited builds",
      "Dedicated support",
      "Collaboration tools for teams",
      "Integration with existing workflows"
    ]
  }
];

const Tier = ({
  tier,
  isPopular,
  description,
  priceText,
  buttonText,
  buttonVariant,
  buttonLink,
  features
}) => {
  const trackClick = () => {
    trackEvent(EVENTS.PRICING_SELECTED, { meta: { tier: tier } });
  };

  return (
    <div className="prices-tier">
      <div className="prices-header">
        <h3 className="prices-title">{tier}</h3>
        {isPopular && <div className="prices-popular">Popular</div>}
      </div>
      <div className="prices-main">
        <div className="prices-desc">{description}</div>
        <div className="_flex _flex-column">
          <div className="prices-priceText">{priceText}</div>
          <Button
            variant={buttonVariant}
            size="md"
            href={buttonLink}
            onClick={trackClick}
            preventDefault={false}
          >
            {buttonText}
          </Button>
        </div>
      </div>
      <ul className="prices-features">
        {features.map((feature, i) => (
          <li key={i}>{feature}</li>
        ))}
      </ul>
    </div>
  );
};

const Pricing = () => {
  return (
    <main>
      <MetaData page={PAGES.Pricing} />
      <div className="price-intro">
        <h2>Pricing Plans</h2>
        <p>
          We offer flexible pricing plans to meet the needs of game developers
          of all sizes, whether you're an indie developer, a growing studio, or
          an enterprise looking for advanced solutions.
        </p>
      </div>
      <section className="prices">
        {TIERDATA.map((tier, i) => (
          <Tier key={i} {...tier} />
        ))}
      </section>
      <div className="price-outro">
        Choose the plan that best fits your development needs and start building
        games seamlessly. Have questions? Contact us to learn more!
      </div>
    </main>
  );
};

export { Pricing };
