import React from "react";
import classnames from "classnames";
import { Spinner } from "@app/components";

interface Props extends React.ComponentProps<"a"> {
  variant?: "primary" | "secondary" | "outline" | "dark" | "dark-alt";
  size?: "sm" | "md" | "lg";
  onClick?: () => void;
  isLoading?: boolean;
  isCancel?: boolean;
  centeredLoader?: boolean;
  preventDefault?: boolean;
  children: React.ReactNode;
}

const Button = ({
  variant = "primary",
  size = "sm",
  className,
  children,
  isLoading,
  href = "#",
  onClick,
  isCancel,
  centeredLoader,
  preventDefault = true,
  ...props
}: Props) => {
  const classNameLocal = classnames(
    `button`,
    `button--${variant}`,
    `button--size-${size}`,
    { "button--isCancel": isCancel },
    { "button--isLoading": isLoading },
    { "button--centerLoader": isLoading && centeredLoader },
    className
  );

  return (
    <a
      href={href}
      className={classNameLocal}
      onClick={(e) => {
        if (!onClick) return;
        preventDefault && e.preventDefault();
        onClick();
      }}
      {...props}
    >
      {children}
      {isLoading && <Spinner className="_ml20" color="white" />}
    </a>
  );
};

export { Button };
