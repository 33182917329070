import { Values } from "./tsUtils";

const EVENTS = {
  PRICING_SELECTED: "Pricing option selected",
  FEEDBACK_TOGGLE: "Toggle feedback",
  QUICKSTART_MODAL_OPEN: "Quickstart modal opened",
  QUICKSTART_MODAL_SUBMIT: "Quickstart form successfully submitted"
} as const;

type Events = Values<typeof EVENTS>;

const trackEvent = (eventName: Events, props?) => {
  // @ts-ignore
  const pirsch = window?.pirsch;
  pirsch && pirsch(eventName, props);
};

export { EVENTS, trackEvent };
