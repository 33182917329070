import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState
} from "react";
import classnames from "classnames";

import { CopyThis } from "@app/components/Icons";
import { useIntersectionObserver } from "@app/utils/hooks";
import { TerminalLineProps } from "./TerminalLine";

interface Props extends React.ComponentProps<"div"> {
  mode?: "bright" | "dark";
  title?: string;
  afterCopy?: () => void;
  allowCopy?: boolean;
  waitForVisibility?: boolean;
}

const Terminal = ({
  title,
  mode,
  className,
  afterCopy,
  children,
  allowCopy = true,
  waitForVisibility = true
}: Props) => {
  const terminalRef = useRef<HTMLDivElement>(null);
  const [currentLine, setCurrentLine] = useState(0);
  const { isIntersecting: isVisible, ref } = useIntersectionObserver({
    threshold: 0.5
  });

  const scrollToEnd = async () => {
    if (terminalRef.current)
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
  };

  const copyToClipboard = () => {
    if (terminalRef.current) {
      navigator.clipboard.writeText(terminalRef.current.innerText);
    }
    if (typeof afterCopy === "function") afterCopy();
  };

  const classes = classnames("terminal", className, {
    "terminal--dark": mode === "dark",
    "terminal--bright": mode === "bright"
  });

  const wait = waitForVisibility ? waitForVisibility && isVisible : true;

  useEffect(() => {
    scrollToEnd();
  }, [currentLine]);

  return (
    <div className={classes} ref={ref}>
      <div className="terminal-header">
        {title && title}
        {allowCopy && (
          <a className="terminal-copyThis" onClick={copyToClipboard}>
            <CopyThis />
          </a>
        )}
      </div>
      <div className="terminal-content" ref={terminalRef}>
        {React.Children.map(children, (child, i) =>
          React.cloneElement(
            child as ReactElement<PropsWithChildren<TerminalLineProps>>,
            {
              isActive: currentLine === i && wait,
              isDone: currentLine > i,
              onFinish: () => {
                setCurrentLine((l) => (l += 1));
              }
            }
          )
        )}
      </div>
    </div>
  );
};

export { Terminal };
