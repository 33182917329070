import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { PAGEMETA, Pages } from "@app/constants";

const MetaData = ({ page }: { page: Pages }) => {
  if (!PAGEMETA || !PAGEMETA[page]) return;

  const title = PAGEMETA[page]?.title;
  const desc = PAGEMETA[page]?.desc;
  const canonical = PAGEMETA[page]?.canonical;

  return (
    <HelmetProvider>
      <Helmet>
        {title && <title>{title}</title>}
        {desc && <meta name="description" content={desc} />}
        {canonical && <link rel="canonical" href={canonical} />}
      </Helmet>
    </HelmetProvider>
  );
};

export { MetaData };
