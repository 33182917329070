import React from "react";
import { Portal } from "@app/components";
import { useDelayedUnmout } from "@app/utils/hooks";
import { Content } from "./Content";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  title?: string;
}

const PopUp = ({ children, onClose, open, title }: Props) => {
  const delayedUnmount = useDelayedUnmout(open);
  if (!delayedUnmount) return null;

  return (
    <Portal>
      <div className="modal" role="dialog">
        <Content open={open} onClose={onClose}>
          {title && <h1>{title}</h1>}
          {children}
        </Content>
      </div>
    </Portal>
  );
};

export { PopUp };
