import React from "react";
import { PAGES } from "@app/constants";
import { MetaData } from "../_Shared";

const PageNotFound = () => (
  <main>
    <MetaData page={PAGES[404]} />
    <section className="grid">
      <h2>Page not found.</h2>
    </section>
  </main>
);

export { PageNotFound };
