// @ts-nocheck

import React from "react";
import { Terminal } from "@app/components";
import { TerminalLine } from "@app/components/Terminal/TerminalLine";
import { showToast } from "@app/utils/emitters";
import parse from "html-react-parser";

const Typing = () => (
  <Terminal
    mode="dark"
    afterCopy={() => showToast("Text Copied.")}
    className="hero-terminal"
  >
    <TerminalLine className="nearwhite" postDelay={1000}>
      <span postDelay={100} typeDelay={50}>
        npm
      </span>
      <span postDelay={0}> </span>
      <span postDelay={200} className="blue" typeDelay={50}>
        install -g
      </span>
      <span postDelay={0}> </span>
      <span postDelay={500} className="red" typeDelay={40}>
        shipthis
      </span>
    </TerminalLine>
    <TerminalLine type="progress" noPrompt postDelay={500}></TerminalLine>
    <TerminalLine noPrompt typeDelay={0} postDelay={50}>
      added 231 packages in 6s
    </TerminalLine>
    <TerminalLine className="green" noPrompt typeDelay={0} postDelay={200}>
      ShipThis installed 😎
    </TerminalLine>

    <TerminalLine className="nearwhite" postDelay={1000}>
      <span postDelay={1000}> </span>
    </TerminalLine>

    <TerminalLine className="nearwhite" postDelay={1000}>
      <span postDelay={500} className="red" typeDelay={40}>
        shipthis
      </span>
      <span postDelay={0}> </span>
      <span postDelay={100} typeDelay={50} className="blue">
        login
      </span>
    </TerminalLine>

    {/* <TerminalLine>
      <span className="red">npm </span>
      <span>install </span>
      <span>react 🥺</span>
    </TerminalLine>
    <TerminalLine typeDelay={0} postDelay={100} noPrompt>
      <span className="white">yarn add v1.22.19</span>
    </TerminalLine>
    <TerminalLine type="progress" noPrompt></TerminalLine>
    <TerminalLine typeDelay={0} postDelay={100} noPrompt>
      <span className="yellow">warning</span>
      <span> package.json: "test" is also the name of a node core module</span>
    </TerminalLine>
    <TerminalLine typeDelay={0} postDelay={1200} noPrompt>
      <span className="red">error</span>
      <span> Missing list of packages to add to your project.</span>
    </TerminalLine>
    <TerminalLine>yarn add npm</TerminalLine>
    <TerminalLine typeDelay={0} postDelay={100} noPrompt>
      yyyyyy1234
    </TerminalLine>
    <TerminalLine typeDelay={0} postDelay={100} noPrompt>
      oh yes it's typing 🌴🌴🚤🐑
    </TerminalLine>
    <TerminalLine typeDelay={0} postDelay={100} noPrompt>
      ok let's see if this thing can
    </TerminalLine>
    <TerminalLine typeDelay={0} postDelay={100} noPrompt>
      scroll as well?
    </TerminalLine>
    <TerminalLine typeDelay={0} postDelay={100} noPrompt>
      Duuuuuude
    </TerminalLine>
    <TerminalLine typeDelay={0} postDelay={100} noPrompt>
      it scrolls!
    </TerminalLine>
    <TerminalLine>
      <span className="red">Ou</span>
      <span className="yellow">hh</span>
      <span className="white">yeah!</span>
    </TerminalLine> */}
  </Terminal>
);

export { Typing };
