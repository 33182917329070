import React, { useEffect } from "react";
import { StarsMinimalistic } from "@app/components/Icons";
import { animated, useSpring, useSpringValue } from "@react-spring/web";
import { useLocalStorage } from "@app/utils/hooks";

const Banner = () => {
  const [animtedIn, setAnimtedIn] = useLocalStorage("bannerAnimated", "");

  const api = useSpringValue(animtedIn ? 0 : -100, {
    config: {
      friction: 12
    }
  });

  useEffect(() => {
    if (animtedIn) return;
    api.start(0);
    setAnimtedIn("true");
  }, [animtedIn]);

  return (
    <animated.div className="banner banner--top" style={{ translateY: api }}>
      <StarsMinimalistic />
      <div className="banner-text">
        ShipThis BETA registration now open. Secure your free lifetime account{" "}
        <a href="/beta">here</a>.
      </div>
    </animated.div>
  );
};

export { Banner };
