import { Values } from "../utils/tsUtils";

export type Pages = Values<typeof PAGES>;

export const PAGES = {
  "404": "404",
  Auth: "Auth",
  BetaSignup: "BetaSignup",
  Docs: "Docs",
  Home: "Home",
  Pricing: "Pricing",
  Privacy: "Privacy",
  Terms: "Terms"
} as const;

interface PageMeta {
  title: string;
  desc: string;
  canonical?: string;
}

export const PAGEMETA: Record<string, PageMeta> = {
  default: {
    title: "ShipThis – App Store ready Godot games in minutes!",
    desc: "Focus on building great games while we take care of getting them shipped to the App Store."
  },
  "404": {
    title: "ShipThis – Page not found.",
    desc: "Page not found. The page you're looking for doesn't exist or may have been moved."
  },
  Auth: {
    title: "ShipThis – Login / Signup.",
    desc: "Log in to the shipThis dashboard to view your builds.",
    canonical: "https://shipthis.cc/login"
  },
  BetaSignup: {
    title: "ShipThis – Beta Tester Signup.",
    desc: "Get a free Shipthis Beta tester account.",
    canonical: "https://shipthis.cc/beta"
  },
  Docs: {
    title: "ShipThis – Documentation",
    desc: "Explore the ShipThis documentation to learn how to build and publish games across platforms using our powerful CLI tool.",
    canonical: "https://shipthis.cc/docs"
  },
  Home: {
    title: "ShipThis – App Store ready Godot games in minutes!",
    desc: "Focus on building great games while we take care of getting them shipped to the App Store.",
    canonical: "https://shipthis.cc"
  },
  Privacy: {
    title: "ShipThis – Privacy Policy",
    desc: "Learn how ShipThis protects your data and privacy in compliance with industry standards.",
    canonical: "https://shipthis.cc/privacy"
  },
  Pricing: {
    title: "ShipThis – Pricing",
    desc: "View ShipThis pricing plans and find the right package for building and deploying your games with ease.",
    canonical: "https://shipthis.cc/pricing"
  },
  Terms: {
    title: "ShipThis – Terms and Conditions!",
    desc: "Read the terms and conditions for using ShipThis, including guidelines for service usage and user responsibilities.",
    canonical: "https://shipthis.cc/terms"
  }
};
