import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { castObjectDates } from "@app/utils/dates";
import { AndroidSetupStatus } from "@app/types";

import { getAuthHeaders } from "./useIsSignedIn";

export async function fetchAndroidSetupStatus(
  projectId: string
): Promise<AndroidSetupStatus> {
  try {
    const headers = getAuthHeaders();
    const url = `${API_URL}/projects/${projectId}/credentials/android/key/status/`;
    const response = await axios.get(url, { headers });
    return castObjectDates<AndroidSetupStatus>(response.data);
  } catch (error) {
    console.warn("fetchAndroidSetupStatus Error", error);
    throw error;
  }
}

export const useAndroidSetupStatus = (
  projectId: string
): UseQueryResult<AndroidSetupStatus> => {
  return useQuery<AndroidSetupStatus, AxiosError>({
    queryKey: cacheKeys.androidSetupStatus(projectId),
    queryFn: () => fetchAndroidSetupStatus(projectId),
    // Status changes frequently, so we want to keep it fresh
    refetchInterval: 1000 * 5,
    staleTime: 1000 * 5,
  });
};
