import useMeasure from "react-use-measure";
import React, { useEffect, useRef, useState } from "react";
import { Button, Logo, StatusMessage, X } from "@app/components";

import axios from "axios";
import { API_URL } from "@app/constants";
import { getErrorMessage } from "@app/utils/errors";
import { trackEvent, EVENTS } from "@app/utils/trackEvent";

const QuickstartForm = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<null | string>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleRequestQuickstart = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await axios.post(`${API_URL}/contact/quickstart`, {
        email
      });
      setEmail("");
      trackEvent(EVENTS.QUICKSTART_MODAL_SUBMIT);
      setIsSuccess(true);
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleRequestQuickstart();
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef.current]);

  return (
    <div className="modal--quickstart">
      {isSuccess ? (
        <div className="_mt5 _mb5">Thanks, we will email you the guide.</div>
      ) : (
        <>
          <Logo size="sm" className="_mb10" />
          <h2 className="modal--quickstart-headline">
            Email me the Quickstart guide.
          </h2>
          <p className="modal--quickstart-intro">
            We value your privacy. Your email will only be used to send you the
            quickstart guide.
          </p>
          {error && (
            <StatusMessage type="error" className="_mb10">
              {error}
            </StatusMessage>
          )}
          <form onSubmit={onSubmit} className="modal--quickstart-form">
            <input
              ref={inputRef}
              type="email"
              autoFocus
              placeholder="Email"
              className="auth_input"
              disabled={isLoading}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError(null);
              }}
            />
            <Button
              size="md"
              className="_mt10"
              variant="primary"
              isLoading={isLoading}
              onClick={handleRequestQuickstart}
            >
              Send me the guide
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export { QuickstartForm };
