import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { queryClient, setCurrentJWT } from "@app/utils/query";
import { SelfWithJWT } from "@app/types";
import { castObjectDates } from "@app/utils/dates";

export interface RequestOTPParams {
  email: string;
}

export interface SubmitOTPParams extends RequestOTPParams {
  otp: string;
  source?: string;
}

export const useIsSignedInMutation = () => {
  return useMutation({
    mutationFn: async (params: RequestOTPParams | SubmitOTPParams) => {
      if ("otp" in params) {
        // SubmitOTPParams
        const { data: selfWithJWT } = await axios.post(
          `${API_URL}/auth/email/verify`,
          { ...params, source: params.source || "web" }
        );
        return castObjectDates<SelfWithJWT>(selfWithJWT);
      }
      await axios.post(`${API_URL}/auth/email/send`, params);
      return; // don't save JWT
    },
    onSuccess: async (data?: SelfWithJWT) => {
      if (!data) return; // the request for the OTP
      const { jwt, ...self } = data;
      setCurrentJWT(jwt as string);
      queryClient.setQueryData(cacheKeys.isSignedIn, true);
      queryClient.setQueryData(cacheKeys.self, self);
    },
  });
};
