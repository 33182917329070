import React from "react";
import { PAGES } from "@app/constants";
import { MetaData } from "../_Shared";

const Docs = () => {
  return (
    <main>
      <MetaData page={PAGES.Docs} />
      <section className="docs">Docs handled by Docusaurus.</section>
    </main>
  );
};

export { Docs };
