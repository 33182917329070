import React, { useEffect, useState } from "react";
import classnames from "classnames";
import parse from "html-react-parser";

export interface TerminalLineProps extends React.ComponentProps<"div"> {
  // Type
  type?: "progress";
  // Style
  noPrompt?: boolean;
  // Progress styling
  isActive?: boolean;
  isDone?: boolean;
  // Callback
  onFinish?: () => void;
  // Timing
  preDelay?: number;
  postDelay?: number;
  typeDelay?: number;
}

const TerminalLine = ({
  noPrompt,
  type,
  isActive,
  isDone,
  children,
  preDelay = 0,
  postDelay = 1000,
  typeDelay = 25,
  onFinish = () => {},
  className,
  ...props
}: TerminalLineProps) => {
  const [text, setText] = useState("");

  const wait = (time: number) =>
    new Promise((resolve) => setTimeout(resolve, time));

  const run = async () => {
    await wait(preDelay);

    if (type === "progress") {
      await showProgress();
    } else if (typeof children === "string") {
      await typeString(children, typeDelay);
    } else if (typeof children === "object") {
      await typeChildren(children);
    }

    await wait(postDelay);
    onFinish();
  };

  // Handles showing a progress-bar

  const showProgress = async () => {
    const progressLength = 20;
    const progressChar = "█";
    const chars = progressChar.repeat(progressLength);
    const progressPercent = 100;

    const runIt = async () => {
      for (let i = 1; i < chars.length + 1; i++) {
        const percent = Math.round((i / chars.length) * 100);
        const text = `${chars.slice(0, i)} ${percent}%`;
        setText(text);

        if (typeDelay > 0) await wait(typeDelay);
        if (percent > progressPercent) break;
      }
    };

    await runIt();
  };

  // Writes out a "string" of text

  const typeString = async (text: string, typeDelay: number) => {
    const runIt = async () => {
      for (const char of text) {
        setText((t) => `${t}${char}`);
        if (typeDelay > 0) await wait(typeDelay);
      }
    };

    await runIt();
  };

  // Handles <span>myText</span>

  const typeChildren = async (children: any) => {
    const runIt = async () => {
      const c = children?.length ? children : [children]; // Allow for a single child

      for (let i = 0; i < c.length; i++) {
        const { type, props } = c[i];
        const className = props.className ? `class="${props.className}"` : "";

        console.log(props.children);

        setText((t) => `${t}<${type} ${className}>`);
        await typeString(props.children, props.typeDelay || typeDelay);
        setText((t) => `${t}</${type}>`);
        await wait(props.postDelay);
      }
    };

    await runIt();
  };

  useEffect(() => {
    if (isActive) run();
  }, [isActive]);

  return (
    <div
      className={classnames("terminal-line", className, {
        "terminal-line--active": isActive && text,
        "terminal-line--done": isDone,
        "terminal-line--noPrompt": noPrompt
      })}
      {...props}
    >
      {parse(text)}
    </div>
  );
};

export { TerminalLine };
