import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

interface Props extends React.ComponentProps<"div"> {}

const Footer = ({ className, ...props }: Props) => {
  return (
    <div className={classNames("auth_footer", className)}>
      <div className="auth_footer_links">
        <Link to="/">Home</Link>
        <a href="/docs">Docs</a>
        <Link to="/terms">Terms</Link>
        <Link to="/privacy">Privacy</Link>
      </div>
      © HI 2024
    </div>
  );
};

export { Footer };
